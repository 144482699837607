

.Result {
    display: inline-block;
    margin: 5px!important;
    padding: 5px!important;
    color: #ffffff!important;
    font-family: "Roboto", "Helvetica";
    font-size: 13px!important;
    height: 80px;
    width: 230px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    vertical-align: middle;
}

.main-stop {
    stop-color: red;
}

.alt-stop {
    stop-color: green;
}

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
  }
  
  li ol > li {
    margin: 0;
  }
  
  li ol > li:before {
    content: counters(item, ".") " ";
  }
